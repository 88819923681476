@use "../global" as *;
.l-header {
  background-color: $white;
  @if $is-fixed == true {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    height: $headerHeight;
    margin: 0 auto;
    position: relative;
    z-index: 100;
    @include mq-max($changePoint) {
      display: block;
      height: $headerHeight-sp;
      padding: 0;
      position: fixed;
      top: 0;
      background: transparent;
    }
  }

  h1,.logo {
    margin-bottom: 0;
    margin-right: 20px;
    line-height: 0;
    display: inline-block;
    width: 280px; // 状況によって変更可
    @include mq-max($changePoint) {
      margin: 0;
    }
    img {
      width: 100%;
      padding-left: 20px;
      @include mq-max($changePoint) {
        padding-left: 0;
        width: 160px; // 状況によって変更可
      }
    }
  }
  .logo {
    img {
      opacity: 1;
      transition: opacity $transition;
    }
    &:hover {
      img {
        opacity: .7;
      }
    }
  }

  &__modules {
    position: relative;
    line-height: 0;
    @include mq-max($changePoint) {
      height: 100%;
      padding: 10px;
      background-color: $white;
      line-height: 0;
      display: flex;
      align-items: center;
    }
  }
}
