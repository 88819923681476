@use "../global" as *;
////サイトレイアウト構造を記述

#container {}
#main {
  display: block;
}
#contents {
  padding: 0;
  @include mq-max(sm) {
    width: $full-size;
    padding: 0;
  }
}


//全体共通で使うCSS定義
html {
  &.open {
    overflow: hidden;
    body {
      overflow: hidden;
    }
  }
}

body {
  color: $textColor;
  font-family: $yugothic;
  line-height: $lineHeight;
  letter-spacing: .02em;
  word-wrap: break-word;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "pwid";
  @if $is-fixed == true {
    margin-top: $headerHeight;
    @include mq-max(md) {
      margin-top: $headerHeight-sp;
    }
  }

  //英語サイト
  .is-lang--eng & {
    letter-spacing: normal;
  }
}


//  見出し
///////////////////////////////////////////////////////////////////////////////////////////////////////
h2,h3,h4,h5 {
  line-height: $lineHeight_heading;
  letter-spacing: .04em;

  //英語サイト
  .is-lang--eng & {
    letter-spacing: normal;
  }
}
h1 {
  font-size: 30px;
  @include mq-max(xs) {
    font-size: 24px;
  }
}
h2 {
  font-size: 28px;
  margin-bottom: 30px;
  @include mq-max(xs) {
    font-size: 22px;
    margin-bottom: 24px;
  }
}
h3 {
  font-size: 22px;
  margin-bottom: 15px;
  @include mq-max(xs) {
    font-size: 18px;
    margin-bottom: 12px;
  }
}
h4 {
  font-size: 18px;
  margin-bottom: 15px;
  @include mq-max(xs) {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
h5 {
  font-size: 16px;
  margin-bottom: 10px;
  @include mq-max(xs) {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

//  文章
///////////////////////////////////////////////////////////////////////////////////////////////////////
a {
  text-decoration: none;
  color: $keyColor;
  &[href^="javascript:void(0)"] {
    pointer-events: none;
  }
  &[target="_blank"]{
    &::after{
      @include fa('f35d');
      margin-left: 10px;
      -webkit-font-smoothing:antialiased;
    }
  }
  &[href$=".pdf"] {
    &::after {
      @include fa('f1c1');
      font-weight: 400;
      color: $red;
      margin-left: 10px;
      -webkit-font-smoothing:antialiased;
    }
  }
  &:not([class]) {
    color: $keyColor;
    transition: all $transition;
    @media (any-hover: hover) {
      &:hover {
        color: darken($keyColor, 10%);
      }
    }
  }
}

p {
  font-size: 16px;
  margin-bottom: 1.3em;
  line-height: 2;
  &:last-child {
    margin-bottom: 0;
  }
  @include mq-max(xs) {
    font-size: 15px;
    line-height: 1.8;
    &:last-child {
      margin-bottom: 0;
    }
  }
}


//  強調
strong {
  font-weight: 600;
}

//  画像
img {
  //SVGのIE対応
  &[src$=".svg"] {
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      width: 100%;
      height: auto;
    }
  }
}
figure {
  img {
    width: 100%;
  }
}
picture {
  display: block;
  line-height: 1;
  img {
    width: 100%;
  }
}

// Lists
ul, ol {
  list-style-type: none;
}

br {
  line-height: 0;//Firefoxバグ対応
}