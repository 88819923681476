@use "../global" as *;
///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ハンバーガーメニュー

///////////////////////////////////////////////////////////////////////////////////////////////////////
.c-toggleBtn {
  @include mq-max($changePoint) {
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 42px;
    height: 42px;
    cursor: pointer;
    z-index: 3;
    span {
      display: block;
      position: absolute;
      width: 28px;
      border-bottom: solid 3px $keyColor;
      transition: .35s ease-in-out; //変化の速度を指定
      left: 6px;
      &:nth-child(1) {
        top: 5px;
      }
      &:nth-child(2) {
        top: 14px;
      }
      &:nth-child(3) {
        top: 23px;
      }
    }
    &::after {
      content: 'メニュー';
      font-size: 10px;
      font-weight: 600;
      font-family: $zen_maru_gothic;
      color: $keyColor;
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }
    &.is-toggleActive {
      span{
        &:nth-child(1) {
          top: 14px;
          left: 6px;
          transform: rotate(-45deg);
        }
        &:nth-child(2),&:nth-child(3) {
          top: 14px;
          transform: rotate(45deg);
        }
      }
    }
  }
}