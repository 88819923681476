@use "../global" as *;
///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ボタン

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-btn {
  position: relative;
  display: inline-block;
  font-size: 16px;
  padding: 18px 45px 18px 26px;
  width: auto;
  color: $keyColor;
  background-color: #fff;
  line-height: 1.25;
  border: 2px solid $keyColor;
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
  transition: color $transition,
              background-color $transition;
  &:last-child {
    margin-bottom: 0;
  }
  &::after {
    @include fa('f054');
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    transition: right $transition;
    line-height: 1;
  }
  &:hover {
    color: $white;
    background-color: $keyColor;
    &::after {
      right: 10px;
    }
  }


  //PDF
  &[href$=".pdf"] {
    &:hover {
      &::after {
        right: 16px;
        color: $white;
      }
    }
  }

  //外部リンク
  &[target="_blank"] {
    &::after {
      color: $keyColor;
    }
    &:hover {
      &::after {
        right: 16px;
        color: $white;
      }
    }
  }


  //Modifier
  //小さいボタン
  &--small {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  //反転
  &--reverse {
    color: $white;
    background-color: $keyColor;
    &:hover {
      color: $keyColor;
      background-color: $white;
    }
  }
}